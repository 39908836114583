import * as React from 'react';
import { Link } from 'gatsby';

const defaultLabels = {
  marketplace: 'Try it free',
  docs: 'Documentation',
  migration: 'Migration Pathway',
  pricing: 'Pricing',
};

const secondaryClass = 'button-secondary animate-underline';

const defaultClassNames = {
  demo: 'button-call-to-action animate-underline',
  marketplace: 'button-primary animate-underline',
  docs: secondaryClass,
  migration: secondaryClass,
};
const ProductLinks = ({
  slug = 'dsapps_website',
  urls,
  labels,
  classNames,
}) => {
  const { marketplace, demo, docs, migration, pricing } = urls;
  const texts = { ...defaultLabels, ...labels };
  const classes = { ...defaultClassNames, ...classNames };

  const iframeDemoUrl = demo
    ? `${demo}?embed_domain=www.dsapps.dev&embed_type=Inline`
    : '';

  const utmSuffix = `${
    marketplace && marketplace.indexOf('?') > 0 ? '&' : '?'
  }utm_source=dsapps.dev&utm_medium=productlink&utm_campaign=${slug.replaceAll(
    '/',
    ''
  )}`;

  return (
    <>
      {demo && (
        <a
          target="_blank"
          href={demo}
          data-demo-url={demo}
          className={classes.demo}
          aria-controls="demo-form-wrapper"
          rel="noreferrer"
        >
          Book a demo
        </a>
      )}
      {marketplace && (
        <a
          target="_blank"
          href={`${marketplace}${utmSuffix}`}
          className={`${classes.marketplace} iframed-hidden`}
          rel="noreferrer"
        >
          {texts.marketplace}
        </a>
      )}
      {docs && (
        <a
          target="_blank"
          href={docs}
          className={classes.docs}
          rel="noreferrer"
        >
          {texts.docs}
        </a>
      )}

      {migration && (
        <a
          target="_blank"
          href={migration}
          className={`${classes.migration} iframed-hidden`}
          rel="noreferrer"
        >
          {texts.migration}
        </a>
      )}
      {pricing && (
        <Link
          to={`/products/${slug}pricing/`}
          className={`${classes.migration} iframed-hidden`}
        >
          {texts.pricing}
        </Link>
      )}

      {demo && (
        <div id="demo-form-wrapper" className="hidden" role="dialog">
          <button type="button" title="Close form" aria-label="Close">
            ✕
          </button>
          <iframe title="Demo Form" id="demo-form" src={iframeDemoUrl} />
        </div>
      )}
    </>
  );
};

export default ProductLinks;
