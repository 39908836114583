/* eslint-disable camelcase */
import * as React from 'react';
import { graphql, Link } from 'gatsby';
import { GatsbySeo } from 'gatsby-plugin-next-seo';
import Helmet from 'react-helmet';

import Layout from '../../../components/layout';
import Rule from '../../../components/rule';
import Section from '../../../components/section';
import ProductLinks from '../../../components/products/ProductLinks';

export const query = graphql`
  query QueryProductDocs($id: String) {
    mdx(id: { eq: $id }, fileAbsolutePath: { regex: "/src/mdx/products/" }) {
      frontmatter {
        title
        tagline
        urls {
          docs
        }
      }
    }
  }
`;

const ProductDocsPage = ({ data }) => {
  if (!data.mdx) {
    // for blog & policies
    return null;
  }

  const { frontmatter, slug } = data.mdx;
  const { title, urls } = frontmatter;

  const docs = urls ? urls.docs : null;

  const noOfSeconds = 4;

  return (
    <>
      <GatsbySeo noindex />
      {docs ? (
        <Helmet
          htmlAttributes={{
            lang: 'en',
          }}
        >
          <meta httpEquiv="refresh" content={`0; URL='${docs}'`} />
        </Helmet>
      ) : (
        <>
          <Helmet
            htmlAttributes={{
              lang: 'en',
            }}
          >
            <meta httpEquiv="refresh" content={`${noOfSeconds}; URL='../'`} />
          </Helmet>
          <Layout pageTitle={title}>
            <Section>
              <section className="text-gray-600 dark:text-gray-400 body-font">
                <div className="container flex md:flex-row md:flex-row flex-col items-center">
                  <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col items-start md:text-left text-left">
                    <h2 className="mb-1 font-sans text-xs font-medium tracking-widest text-blue-500 title-font">
                      <Link to="../" className="uppercase animate-underline">
                        Back
                      </Link>
                    </h2>
                    <Rule />
                    <h1 className="my-4 text-2xl text-gray-900 dark:text-gray-100 sm:text-4xl title-font">
                      {title}
                    </h1>

                    <div>
                      <p className="py-10 text-xl ">
                        Sorry, no documentation is available for this product.
                      </p>
                      <p className="py-10 text-xl ">
                        Redirecting to the product page in {noOfSeconds}s.
                      </p>
                    </div>
                  </div>
                </div>
              </section>
            </Section>
          </Layout>
        </>
      )}
    </>
  );
};

export default ProductDocsPage;
